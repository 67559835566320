<template>
  <div class="wrap">
    <cardTitleCom cardTitle="门店目标业绩" v-loading="loading">
      <template slot="cardContent">
        <div class="tableContent">
          <div class="tableBtn">
            <div>
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="$router.push('/shop/base/saleTarget/saleTargetDetail')"
                >新增</el-button
              >
            </div>
            <!-- 右边盒子 -->
            <div>
              <el-button
                size="mini"
                class="marR10"
                icon="el-icon-refresh"
                @click="test($event)"
                >刷新
              </el-button>
            </div>
          </div>
          <!-- 目标列表 -->
          <div>
            <!-- 目标业绩  今年 -->
            <div class="targetPerformance" v-loading="thisYearLoading">
              <!-- 按钮 -->
              <div class="targetTurnover padL10 padR10">
                <!-- 左边 -->
                <div>
                  <span class="fontS14 marR10"
                    >{{ thisYear }}年 目标营业额(元)</span
                  >
                  <span class="fontS14 marR10">选择门店</span>
                  <el-select
                    class="inputWidthM marR15"
                    v-model="thisYearShopArr"
                    @change="getChangeShop('thisYear')"
                    placeholder="全部门店"
                    filterable
                    clearable
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in shopInfoData"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- 具体目标 -->
              <div class="targetContent">
                <!-- 左边 -->
                <div class="targetSummary">
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualTarget.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">全店年度目标</p>

                      <el-tooltip
                        effect="dark"
                        :content="thisYearObj.totalAmount"
                        placement="top"
                        v-if="thisYearObj.totalAmount"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ thisYearObj.totalAmount }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualsales.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">门店年度销售额</p>
                      <el-tooltip
                        effect="dark"
                        :content="thisYearObj.monthSaleTotal"
                        placement="top"
                        v-if="thisYearObj.monthSaleTotal"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ thisYearObj.monthSaleTotal }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualRecharge.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">门店年度充值额</p>
                      <el-tooltip
                        effect="dark"
                        :content="thisYearObj.monthFillTotal"
                        placement="top"
                        v-if="thisYearObj.monthFillTotal"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ thisYearObj.monthFillTotal }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 右边 -->
                <div class="targetData">
                  <!-- 上面 -->
                  <div class="targetLine">
                    <!-- 每一项 1-->
                    <div class="targetBox">
                      <p class="fontS14 targetBoxTop">1月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店销售</span>
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale01"
                          placement="top"
                          v-if="thisYearObj.monthSale01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale01
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店充值</span>
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill01"
                          placement="top"
                          v-if="thisYearObj.monthFill01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill01
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 lineWhole">全店</span>
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget01"
                          placement="top"
                          v-if="thisYearObj.shopTarget01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget01
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 2-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">2月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale02"
                          placement="top"
                          v-if="thisYearObj.monthSale02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill02"
                          placement="top"
                          v-if="thisYearObj.monthFill02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget02"
                          placement="top"
                          v-if="thisYearObj.shopTarget02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 3-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">3月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale03"
                          placement="top"
                          v-if="thisYearObj.monthSale03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill03"
                          placement="top"
                          v-if="thisYearObj.monthFill03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget03"
                          placement="top"
                          v-if="thisYearObj.shopTarget03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 4-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">4月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale04"
                          placement="top"
                          v-if="thisYearObj.monthSale04"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale04
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill04"
                          placement="top"
                          v-if="thisYearObj.monthFill04"
                        >
                          <span
                            class="fontS14 omit"
                            style="width: 80px"
                            v-if="thisYearObj.monthFill04"
                            >{{ thisYearObj.monthFill04 }}</span
                          >
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget04"
                          placement="top"
                          v-if="thisYearObj.shopTarget04"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget04
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 5-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">5月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale05"
                          placement="top"
                          v-if="thisYearObj.monthSale05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale05
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill05"
                          placement="top"
                          v-if="thisYearObj.monthFill05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill05
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget05"
                          placement="top"
                          v-if="thisYearObj.shopTarget05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget05
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 6-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">6月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale06"
                          placement="top"
                          v-if="thisYearObj.monthSale06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill06"
                          placement="top"
                          v-if="thisYearObj.monthFill06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget06"
                          placement="top"
                          v-if="thisYearObj.shopTarget06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- 下面 -->
                  <div class="targetLine">
                    <!-- 每一项 7-->
                    <div class="targetBox">
                      <p class="fontS14 targetBoxTop">7月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店销售</span>
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale07"
                          placement="top"
                          v-if="thisYearObj.monthSale07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店充值</span>
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill07"
                          placement="top"
                          v-if="thisYearObj.monthFill07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 lineWhole">全店</span>
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget07"
                          placement="top"
                          v-if="thisYearObj.shopTarget07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 8-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">8月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale08"
                          placement="top"
                          v-if="thisYearObj.monthSale08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill08"
                          placement="top"
                          v-if="thisYearObj.monthFill08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget08"
                          placement="top"
                          v-if="thisYearObj.shopTarget08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 9-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">9月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale09"
                          placement="top"
                          v-if="thisYearObj.monthSale09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill09"
                          placement="top"
                          v-if="thisYearObj.monthFill09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget09"
                          placement="top"
                          v-if="thisYearObj.shopTarget09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 10-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">10月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale10"
                          placement="top"
                          v-if="thisYearObj.monthSale10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill10"
                          placement="top"
                          v-if="thisYearObj.monthFill10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget10"
                          placement="top"
                          v-if="thisYearObj.shopTarget10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 11-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">11月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale11"
                          placement="top"
                          v-if="thisYearObj.monthSale11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill11"
                          placement="top"
                          v-if="thisYearObj.monthFill11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget11"
                          placement="top"
                          v-if="thisYearObj.shopTarget11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 12-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">12月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthSale12"
                          placement="top"
                          v-if="thisYearObj.monthSale12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthSale12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.monthFill12"
                          placement="top"
                          v-if="thisYearObj.monthFill12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.monthFill12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="thisYearObj.shopTarget12"
                          placement="top"
                          v-if="thisYearObj.shopTarget12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            thisYearObj.shopTarget12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 目标业绩   明年-->
            <div class="targetPerformance" v-loading="nextYearLoading">
              <!-- 按钮 -->
              <div class="targetTurnover padL10 padR10">
                <!-- 左边 -->
                <div>
                  <span class="fontS14 marR10"
                    >{{ nextYear }}年 目标营业额(元)</span
                  >
                  <span class="fontS14 marR10">选择门店</span>
                  <el-select
                    class="inputWidthM marR15"
                    v-model="nextYearShopArr"
                    @change="getChangeShop('nextYear')"
                    placeholder="全部门店"
                    filterable
                    clearable
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in shopInfoData"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- 具体目标 -->
              <div class="targetContent">
                <!-- 左边 -->
                <div class="targetSummary">
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualTarget.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">全店年度目标</p>

                      <el-tooltip
                        effect="dark"
                        :content="nextYearObj.totalAmount"
                        placement="top"
                        v-if="nextYearObj.totalAmount"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ nextYearObj.totalAmount }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualsales.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">门店年度销售额</p>
                      <el-tooltip
                        effect="dark"
                        :content="nextYearObj.monthSaleTotal"
                        placement="top"
                        v-if="nextYearObj.monthSaleTotal"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ nextYearObj.monthSaleTotal }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualRecharge.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">门店年度充值额</p>
                      <el-tooltip
                        effect="dark"
                        :content="nextYearObj.monthFillTotal"
                        placement="top"
                        v-if="nextYearObj.monthFillTotal"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ nextYearObj.monthFillTotal }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 右边 -->
                <div class="targetData">
                  <!-- 上面 -->
                  <div class="targetLine">
                    <!-- 每一项 1-->
                    <div class="targetBox">
                      <p class="fontS14 targetBoxTop">1月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店销售</span>
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale01"
                          placement="top"
                          v-if="nextYearObj.monthSale01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale01
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店充值</span>
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill01"
                          placement="top"
                          v-if="nextYearObj.monthFill01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill01
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 lineWhole">全店</span>
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget01"
                          placement="top"
                          v-if="nextYearObj.shopTarget01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget01
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 2-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">2月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale02"
                          placement="top"
                          v-if="nextYearObj.monthSale02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill02"
                          placement="top"
                          v-if="nextYearObj.monthFill02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget02"
                          placement="top"
                          v-if="nextYearObj.shopTarget02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 3-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">3月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale03"
                          placement="top"
                          v-if="nextYearObj.monthSale03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill03"
                          placement="top"
                          v-if="nextYearObj.monthFill03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget03"
                          placement="top"
                          v-if="nextYearObj.shopTarget03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 4-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">4月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale04"
                          placement="top"
                          v-if="nextYearObj.monthSale04"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale04
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill04"
                          placement="top"
                          v-if="nextYearObj.monthFill04"
                        >
                          <span
                            class="fontS14 omit"
                            style="width: 80px"
                            v-if="nextYearObj.monthFill04"
                            >{{ nextYearObj.monthFill04 }}</span
                          >
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget04"
                          placement="top"
                          v-if="nextYearObj.shopTarget04"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget04
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 5-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">5月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale05"
                          placement="top"
                          v-if="nextYearObj.monthSale05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale05
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill05"
                          placement="top"
                          v-if="nextYearObj.monthFill05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill05
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget05"
                          placement="top"
                          v-if="nextYearObj.shopTarget05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget05
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 6-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">6月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale06"
                          placement="top"
                          v-if="nextYearObj.monthSale06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill06"
                          placement="top"
                          v-if="nextYearObj.monthFill06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget06"
                          placement="top"
                          v-if="nextYearObj.shopTarget06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- 下面 -->
                  <div class="targetLine">
                    <!-- 每一项 7-->
                    <div class="targetBox">
                      <p class="fontS14 targetBoxTop">7月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店销售</span>
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale07"
                          placement="top"
                          v-if="nextYearObj.monthSale07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店充值</span>
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill07"
                          placement="top"
                          v-if="nextYearObj.monthFill07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 lineWhole">全店</span>
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget07"
                          placement="top"
                          v-if="nextYearObj.shopTarget07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 8-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">8月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale08"
                          placement="top"
                          v-if="nextYearObj.monthSale08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill08"
                          placement="top"
                          v-if="nextYearObj.monthFill08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget08"
                          placement="top"
                          v-if="nextYearObj.shopTarget08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 9-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">9月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale09"
                          placement="top"
                          v-if="nextYearObj.monthSale09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill09"
                          placement="top"
                          v-if="nextYearObj.monthFill09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget09"
                          placement="top"
                          v-if="nextYearObj.shopTarget09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 10-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">10月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale10"
                          placement="top"
                          v-if="nextYearObj.monthSale10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill10"
                          placement="top"
                          v-if="nextYearObj.monthFill10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget10"
                          placement="top"
                          v-if="nextYearObj.shopTarget10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 11-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">11月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale11"
                          placement="top"
                          v-if="nextYearObj.monthSale11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill11"
                          placement="top"
                          v-if="nextYearObj.monthFill11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget11"
                          placement="top"
                          v-if="nextYearObj.shopTarget11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 12-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">12月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthSale12"
                          placement="top"
                          v-if="nextYearObj.monthSale12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthSale12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.monthFill12"
                          placement="top"
                          v-if="nextYearObj.monthFill12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.monthFill12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="nextYearObj.shopTarget12"
                          placement="top"
                          v-if="nextYearObj.shopTarget12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            nextYearObj.shopTarget12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 目标业绩   后年-->
            <div class="targetPerformance" v-loading="afterNextYearLoading">
              <!-- 按钮 -->
              <div class="targetTurnover padL10 padR10">
                <!-- 左边 -->
                <div>
                  <span class="fontS14 marR10"
                    >{{ afterNextYear }}年 目标营业额(元)</span
                  >
                  <span class="fontS14 marR10">选择门店</span>
                  <el-select
                    class="inputWidthM marR15"
                    v-model="afterNextYearShopArr"
                    @change="getChangeShop('afterNextYear')"
                    placeholder="全部门店"
                    filterable
                    clearable
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in shopInfoData"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- 具体目标 -->
              <div class="targetContent">
                <!-- 左边 -->
                <div class="targetSummary">
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualTarget.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">全店年度目标</p>

                      <el-tooltip
                        effect="dark"
                        :content="afterNextYearObj.totalAmount"
                        placement="top"
                        v-if="afterNextYearObj.totalAmount"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ afterNextYearObj.totalAmount }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualsales.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">门店年度销售额</p>
                      <el-tooltip
                        effect="dark"
                        :content="afterNextYearObj.monthSaleTotal"
                        placement="top"
                        v-if="afterNextYearObj.monthSaleTotal"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ afterNextYearObj.monthSaleTotal }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                  <div class="summaryItem">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="require('@/assets/images/annualRecharge.png')"
                    ></el-image>
                    <div class="itemText">
                      <p class="fontS14">门店年度充值额</p>
                      <el-tooltip
                        effect="dark"
                        :content="afterNextYearObj.monthFillTotal"
                        placement="top"
                        v-if="afterNextYearObj.monthFillTotal"
                      >
                        <p class="fontS14 omit" style="width: 105px">
                          {{ afterNextYearObj.monthFillTotal }}
                        </p>
                      </el-tooltip>
                      <span
                        class="fontS14 lightGray"
                        style="width: 105px"
                        v-else
                        >未设置</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 右边 -->
                <div class="targetData">
                  <!-- 上面 -->
                  <div class="targetLine">
                    <!-- 每一项 1-->
                    <div class="targetBox">
                      <p class="fontS14 targetBoxTop">1月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店销售</span>
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale01"
                          placement="top"
                          v-if="afterNextYearObj.monthSale01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale01
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店充值</span>
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill01"
                          placement="top"
                          v-if="afterNextYearObj.monthFill01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill01
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 lineWhole">全店</span>
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget01"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget01"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget01
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 2-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">2月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale02"
                          placement="top"
                          v-if="afterNextYearObj.monthSale02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill02"
                          placement="top"
                          v-if="afterNextYearObj.monthFill02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget02"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget02"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget02
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 3-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">3月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale03"
                          placement="top"
                          v-if="afterNextYearObj.monthSale03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill03"
                          placement="top"
                          v-if="afterNextYearObj.monthFill03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget03"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget03"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget03
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 4-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">4月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale04"
                          placement="top"
                          v-if="afterNextYearObj.monthSale04"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale04
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill04"
                          placement="top"
                          v-if="afterNextYearObj.monthFill04"
                        >
                          <span
                            class="fontS14 omit"
                            style="width: 80px"
                            v-if="afterNextYearObj.monthFill04"
                            >{{ afterNextYearObj.monthFill04 }}</span
                          >
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget04"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget04"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget04
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 5-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">5月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale05"
                          placement="top"
                          v-if="afterNextYearObj.monthSale05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale05
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill05"
                          placement="top"
                          v-if="afterNextYearObj.monthFill05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill05
                          }}</span>
                        </el-tooltip>

                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget05"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget05"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget05
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 6-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">6月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale06"
                          placement="top"
                          v-if="afterNextYearObj.monthSale06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill06"
                          placement="top"
                          v-if="afterNextYearObj.monthFill06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget06"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget06"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget06
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- 下面 -->
                  <div class="targetLine">
                    <!-- 每一项 7-->
                    <div class="targetBox">
                      <p class="fontS14 targetBoxTop">7月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店销售</span>
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale07"
                          placement="top"
                          v-if="afterNextYearObj.monthSale07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 marR20">门店充值</span>
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill07"
                          placement="top"
                          v-if="afterNextYearObj.monthFill07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <span class="fontS14 lineWhole">全店</span>
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget07"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget07"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget07
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 8-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">8月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale08"
                          placement="top"
                          v-if="afterNextYearObj.monthSale08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill08"
                          placement="top"
                          v-if="afterNextYearObj.monthFill08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget08"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget08"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget08
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 9-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">9月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale09"
                          placement="top"
                          v-if="afterNextYearObj.monthSale09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill09"
                          placement="top"
                          v-if="afterNextYearObj.monthFill09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget09"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget09"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget09
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 10-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">10月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale10"
                          placement="top"
                          v-if="afterNextYearObj.monthSale10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill10"
                          placement="top"
                          v-if="afterNextYearObj.monthFill10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget10"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget10"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget10
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 11-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">11月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale11"
                          placement="top"
                          v-if="afterNextYearObj.monthSale11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill11"
                          placement="top"
                          v-if="afterNextYearObj.monthFill11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget11"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget11"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget11
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                    <!-- 每一项 12-->
                    <div class="targetBox targetBoxMar">
                      <p class="fontS14 targetBoxTopItem">12月</p>
                      <!-- 门店销售 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthSale12"
                          placement="top"
                          v-if="afterNextYearObj.monthSale12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthSale12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 门店充值 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.monthFill12"
                          placement="top"
                          v-if="afterNextYearObj.monthFill12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.monthFill12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                      <!-- 全店 -->
                      <div class="targetBoxLine">
                        <el-tooltip
                          effect="dark"
                          :content="afterNextYearObj.shopTarget12"
                          placement="top"
                          v-if="afterNextYearObj.shopTarget12"
                        >
                          <span class="fontS14 omit" style="width: 80px">{{
                            afterNextYearObj.shopTarget12
                          }}</span>
                        </el-tooltip>
                        <span
                          class="fontS14 lightGray"
                          style="width: 80px"
                          v-else
                          >未设置</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { saleTargetListAPI } from '@/api/shop/base/saleTarget' //门店业绩
import { listShopInfo } from '@/api/shop/base/shopInfo' //门店资料
export default {
  name: 'saleTarget',
  components: { cardTitleCom },
  data () {
    return {
      // 今年
      thisYear: undefined,
      // 明年
      nextYear: undefined,
      // 后年
      afterNextYear: undefined,
      // 今年
      thisYearObj: {},
      // 明年
      nextYearObj: {},
      // 后年
      afterNextYearObj: {},
      // 今年
      thisYearShopArr: [],
      // 明年
      nextYearShopArr: [],
      // 后年
      afterNextYearShopArr: [],
      // 遮罩层
      loading: false,
      // 遮罩层   今年
      thisYearLoading: false,
      // 遮罩层   明年
      nextYearLoading: false,
      // 遮罩层   后年
      afterNextYearLoading: false,
      //门店数据
      shopInfoData: [],
      monthList: [
        {
          month: '1月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '2月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '3月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '4月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '5月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '6月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '7月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '8月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '9月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '10月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '11月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        },
        {
          month: '12月',
          shopSale: '门店销售',
          shopRecharge: '门店充值',
          shopWhole: '全店'
        }
      ]
    }
  },
  async created () {
    this.loading = true
    //获取今年、明年、后年日期
    this.getYearDate()
    //获取门店数据
    await this.getShopList()
    //业绩
    await this.getList()
    if (this.monthList.length <= 0) {
      //今年
      this.thisYearObj = {}
      //明年
      this.nextYearObj = {}
      //后年
      this.afterNextYearObj = {}
    } else {
      this.monthList.forEach(item => {
        if (item.yearMonth == this.thisYear) {
          //今年
          this.thisYearObj = item
        } else if (item.yearMonth == this.nextYear) {
          //明年
          this.nextYearObj = item
        } else if (item.yearMonth == this.afterNextYear) {
          //后年
          this.afterNextYearObj = item
        }
      })
    }
    this.loading = false
  },
  methods: {
    //切换门店
    async getChangeShop (value) {
      if (value == 'thisYear') {
        //今年
        this.thisYearLoading = true
        await this.getList({
          yearMonth: this.thisYear,
          shopIds: this.thisYearShopArr
        })
        if (this.monthList.length > 0) {
          this.thisYearObj = this.monthList[0]
        } else {
          this.thisYearObj = {}
        }
        this.$message({
          message: '切换成功',
          type: 'success'
        })
        this.thisYearLoading = false
      } else if (value == 'nextYear') {
        //明年
        this.nextYearLoading = true
        await this.getList({
          yearMonth: this.nextYear,
          shopIds: this.nextYearShopArr
        })
        if (this.monthList.length > 0) {
          this.nextYearObj = this.monthList[0]
        } else {
          this.nextYearObj = {}
        }
        this.$message({
          message: '切换成功',
          type: 'success'
        })
        this.nextYearLoading = false
      } else if (value == 'afterNextYear') {
        //后年
        this.afterNextYearLoading = true
        await this.getList({
          yearMonth: this.afterNextYear,
          shopIds: this.afterNextYearShopArr
        })
        if (this.monthList.length > 0) {
          this.afterNextYearObj = this.monthList[0]
        } else {
          this.afterNextYearObj = {}
        }
        this.$message({
          message: '切换成功',
          type: 'success'
        })
        this.afterNextYearLoading = false
      }
    },
    //获取门店数据
    async getShopList () {
      const res1 = await listShopInfo({ pageNum: 1,
          pageSize: 99999})
      this.shopInfoData = res1.rows
    },
    //获取今年、明年、后年日期
    getYearDate () {
      let currentDate = new Date() // 获取当前日期
      let currentYear = currentDate.getFullYear() // 获取当前年份
      this.thisYear = currentYear // 今年
      this.nextYear = currentYear + 1 // 明年
      this.afterNextYear = currentYear + 2 // 后年
    },
    //跳转详情
    handleDetail (kpiId) {
      this.$router.push({
        name: 'saleTargetDetail',
        query: {
          kpiId: kpiId,
          type: 'Update'
        }
      })
    },
    //列表
    async getList (queryParams) {
      const res = await saleTargetListAPI(queryParams)
      this.monthList = res.rows
      this.monthList.forEach(item => {
        //门店年度销售额
        item.monthSaleTotal = item.monthSaleTotal + ''
        //门店年度充值额
        item.monthFillTotal = item.monthFillTotal + ''
        //全店年度目标
        item.totalAmount = item.totalAmount + ''
        item.monthFill01 = Number(item.monthFill01).toFixed(2)
        item.monthFill02 = Number(item.monthFill02).toFixed(2)
        item.monthFill03 = Number(item.monthFill03).toFixed(2)
        item.monthFill04 = Number(item.monthFill04).toFixed(2)
        item.monthFill05 = Number(item.monthFill05).toFixed(2)
        item.monthFill06 = Number(item.monthFill06).toFixed(2)
        item.monthFill07 = Number(item.monthFill07).toFixed(2)
        item.monthFill08 = Number(item.monthFill08).toFixed(2)
        item.monthFill09 = Number(item.monthFill09).toFixed(2)
        item.monthFill10 = Number(item.monthFill10).toFixed(2)
        item.monthFill11 = Number(item.monthFill11).toFixed(2)
        item.monthFill12 = Number(item.monthFill12).toFixed(2)

        item.monthSale01 = Number(item.monthSale01).toFixed(2)
        item.monthSale02 = Number(item.monthSale02).toFixed(2)
        item.monthSale03 = Number(item.monthSale03).toFixed(2)
        item.monthSale04 = Number(item.monthSale04).toFixed(2)
        item.monthSale05 = Number(item.monthSale05).toFixed(2)
        item.monthSale06 = Number(item.monthSale06).toFixed(2)
        item.monthSale07 = Number(item.monthSale07).toFixed(2)
        item.monthSale08 = Number(item.monthSale08).toFixed(2)
        item.monthSale09 = Number(item.monthSale09).toFixed(2)
        item.monthSale10 = Number(item.monthSale10).toFixed(2)
        item.monthSale11 = Number(item.monthSale11).toFixed(2)
        item.monthSale12 = Number(item.monthSale12).toFixed(2)

        this.$set(
          item,
          'shopTarget01',
          (Number(item.monthFill01) + Number(item.monthSale01)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget02',
          (Number(item.monthFill02) + Number(item.monthSale02)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget03',
          (Number(item.monthFill03) + Number(item.monthSale03)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget04',
          (Number(item.monthFill04) + Number(item.monthSale04)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget05',
          (Number(item.monthFill05) + Number(item.monthSale05)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget06',
          (Number(item.monthFill06) + Number(item.monthSale06)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget07',
          (Number(item.monthFill07) + Number(item.monthSale07)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget08',
          (Number(item.monthFill08) + Number(item.monthSale08)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget09',
          (Number(item.monthFill09) + Number(item.monthSale09)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget10',
          (Number(item.monthFill10) + Number(item.monthSale10)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget11',
          (Number(item.monthFill11) + Number(item.monthSale11)).toFixed(2)
        )
        this.$set(
          item,
          'shopTarget12',
          (Number(item.monthFill12) + Number(item.monthSale12)).toFixed(2)
        )
      })
    },
    //刷新按钮
    test (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 6px 10px;
  background-color: #eaeaea;
  p {
    cursor: default;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  span {
    cursor: default;
    display: inline-block;
  }
  //源单信息表格
  .tableContent {
    padding: 6px 10px;
    // 操作
    .tableBtn {
      height: 50px;
      line-height: 50px;
      background-color: #f9f9fa;
      padding-left: 5px;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
    }
    //目标业绩
    .targetPerformance {
      width: 100%;
      height: 355px;
      border: 1px solid #ebedf0;
      margin-bottom: 20px;
      //营业额
      .targetTurnover {
        height: 40px;
        line-height: 40px;
        background-color: #f9f9fa;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      //   具体目标
      .targetContent {
        padding: 30px 20px;
        display: flex;
        //左边
        .targetSummary {
          padding: 18px 25px 0 0;
          margin-bottom: 18px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-right: 1px solid #ebedf0;
          //每一项
          .summaryItem {
            display: flex;
            margin-bottom: 40px;
            .itemText {
              // 总得信息
              margin-left: 10px;
              line-height: 20px;
            }
          }
        }
        //右边
        .targetData {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 20px;
          flex: 1;
          border-left: 1px solid #ebedf0;
          .targetLine {
            display: flex;
            align-items: center;
            //每一项
            .targetBox {
              margin-bottom: 50px;
              //顶部盒子  1\7月
              .targetBoxTop {
                margin: 0 0 17px 50px;
              }
              //顶部盒子  2...12月
              .targetBoxTopItem {
                margin: 0 0 17px 0;
              }
              //底部内容
              .targetBoxLine {
                .lineWhole {
                  margin-right: 48px;
                }
              }
            }
            //每项间距
            .targetBoxMar {
              margin-left: 120px;
            }
          }
        }
      }
    }
  }
}
</style>
