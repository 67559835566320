<template>
  <div class="wrap" id="wrap">
    <!-- 按钮 -->
    <div class="btnBox" id="search-card">
      <el-button type="danger" icon="el-icon-delete" @click="dialogOptions.show = true" size="mini">回收站
      </el-button>
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="batchPaymode">保存
      </el-button>
      <el-button icon="el-icon-refresh" size="mini" @click="refresh">刷新 </el-button>
    </div>
    <cardTitleCom cardTitle="收款方式">
      <template slot="cardContent">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="通用收款设置" name="universal" class="universal-cashier">
            <div class="tableContent">
              <div class="left-main">
                <el-form ref="form" :model="form" :rules="rules">
                  <!-- 收款方式表格 -->
                  <el-table ref="multipleTable" class="table" v-loading="loading" :data="form.cashierList" border
                    :max-height="tableHeight" :height="tableHeight" @cell-mouse-enter="cellMouseEnter"
                    @cell-mouse-leave="cellMouseLeave">
                    <!-- <el-table-column
                      type="index"
                      width="80"
                      label="序号"
                      align="center"
                    >
                    </el-table-column> -->
                    <el-table-column align="center" :label="tableCellLabel" width="80">
                      <template v-slot="scope">
                        <i v-if="scope.row.hoverRow" @click="row('push', scope.$index)"
                          class="el-icon-circle-plus operatePush"></i>
                        <i v-if="scope.row.hoverRow" @click="row('del', scope.$index)"
                          class="el-icon-remove operateDel"></i>
                        <div v-if="!scope.row.hoverRow">
                          {{ scope.$index + 1 }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="编号" align="center" prop="payModeNo" width="150">
                      <template slot-scope="scope">
                        <el-form-item :prop="'cashierList.' + scope.$index + '.payModeNo'"
                          :rules="rules[`cashierList.payModeNo`]">
                          <el-input v-model="scope.row.payModeNo" :disabled="scope.row.disable"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="名称" align="center" key="payModeName" prop="payModeName" width="160">
                      <template slot-scope="scope">
                        <el-form-item :prop="'cashierList.' + scope.$index + '.payModeName'"
                          :rules="rules[`cashierList.payModeName`]">
                          <el-input v-model="scope.row.payModeName" :disabled="scope.row.disable"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="门店排序" align="center" key="sortNo" prop="sortNo" width="110">
                    </el-table-column>
                    <el-table-column label="适用门店" align="center" prop="shopIds" width="280">
                      <template slot-scope="scope">
                        <SelectLocal :multiple="true" v-model="scope.row.shopIds" style="width: 250px"
                          @selectChange="selectShopChange(scope.row.shopIds, scope.row)" :option="{
                            data: listShop,
                            disabled: [5, 8, 9, 11, 12, 13, 14, 15,16].includes(
                              scope.row.payModeId
                            ),
                            labels: [
                              { title: '编号', label: 'shopNo' },
                              { title: '名称', label: 'shopName' },
                            ],
                            value: 'shopId',
                            label: 'shopName',
                          }" />
                      </template>
                    </el-table-column>
                    <el-table-column label="支付通道" align="center" prop="payChannelId" width="190">
                      <template slot-scope="scope">
                        <SelectRemote v-model="scope.row.payChannelId" style="width: 160px" :disabled="
                            [1, 2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 15,16].includes(
                              scope.row.payModeId
                            )
                          " :option="
                            $select({
                              key: 'listPayChannel',
                              option: {
                                clearable: true,
                                option: {
                                clearable: true,
                                  showItem: [
                                    {
                                      payChannelId: scope.row.payChannelId,
                                      payChannelName: scope.row.payChannelName,
                                    },
                                  ],
                                },
                              },
                            }).option
                          " />
                      </template>
                    </el-table-column>
                    <el-table-column prop="isUse" label="启用" width="120" align="center">
                      <template slot-scope="scope">
                        <el-switch v-model="scope.row.isUse" :active-value="true" :inactive-value="false"
                          :disabled="scope.row.disable"></el-switch>
                      </template>
                    </el-table-column>

                    <el-table-column prop="isRevenue" label="营收" width="120" align="center">
                      <template slot-scope="scope">
                        <el-switch v-model="scope.row.isRevenue" :active-value="true" :inactive-value="false"
                          :disabled="scope.row.disable"></el-switch>
                      </template>
                    </el-table-column>
                    <el-table-column prop="isDisplay" label="门店显示" width="120" align="center">
                      <template slot-scope="scope">
                        <el-switch v-model="scope.row.isDisplay" :active-value="true" :inactive-value="false"
                          :disabled="[5, 8, 9, 11, 12,16].includes(scope.row.payModeId)"></el-switch>
                      </template>
                    </el-table-column>
                    <el-table-column label="快捷键" align="center" prop="hotKeyName" width="160">
                      <template slot-scope="scope">
                        <div style="display: flex; align-items: center">
                          <el-input v-model="scope.row.hotKeyName" readonly
                            @keydown.native="setHotKey($event, scope.$index)"></el-input>
                          <i class="el-icon-circle-close" style="margin-left: 10px; cursor: pointer"
                            @click="setHotKey('', scope.$index)"></i>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column label="备注" align="center" key="remark" prop="remark" min-width="160">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.remark"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="创建人" align="center" key="createBy" prop="createBy" width="130" />
                    <el-table-column label="创建时间" align="center" key="createTime" prop="createTime" width="155" />
                    <el-table-column label="修改人" align="center" key="updateBy" prop="updateBy" width="130" />
                    <el-table-column label="修改时间" align="center" prop="updateTime" width="155" />
                  </el-table>
                </el-form>
                <!-- 分页 -->
              </div>
              <pagination class="pag" v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="门店收款设置" name="shop" class="shop-cashier">
            <div class="left-main">
              <div class="treeBox">
                <div class="title">付款方式</div>
                <div>
                  <!-- 门店搜索区域 -->
                  <div class="x-fc">
                    <el-input class="searchTree" v-model="shopName" placeholder="请输入门店名称" clearable size="small"
                      prefix-icon="el-icon-search" style="margin-bottom: 10px" />
                    <i class="el-icon-arrow-down iconDown" v-show="!isOpenTree" @click="clickOpenTree"></i>
                    <i class="el-icon-arrow-up iconDown" v-show="isOpenTree" @click="clickOpenTree"></i>
                  </div>

                  <!-- 门店tree区域 -->
                  <el-scrollbar :style="{ height: `${tableHeight - 70}px` }">
                    <el-tree node-key="id" :data="shopTreeOptions" :expand-on-click-node="false"
                      :filter-node-method="filterNode" ref="tree" highlight-current :default-expand-all="isExpand"
                      @node-click="handleNodeClick" />
                  </el-scrollbar>
                </div>
              </div>
              <div class="right-main">
                <div class="tableBox">
                  <el-table class="table marB10" v-loading="loading" :data="shopCashierList" border
                    :max-height="tableHeight" :height="tableHeight">
                    <el-table-column type="index" width="80" label="序号" align="center">
                    </el-table-column>
                    <el-table-column label="门店编号" align="center" prop="shopNo" />
                    <el-table-column label="门店名称" align="center" prop="shopName" />
                    <el-table-column label="收款方式编号" align="center" prop="payModeNo" />
                    <el-table-column label="收款方式名称" align="center" prop="payModeName" />

                    <el-table-column label="门店排序" align="center" key="sortNo" prop="sortNo" width="110">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.sortNo"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="isDisplay" label="门店显示" width="120" align="center">
                      <template slot-scope="scope">
                        <el-switch :disabled="
                            !scope.row.isSourceDisplay || scope.row.payModeId == 8
                          " v-model="scope.row.isDisplay" :active-value="true" :inactive-value="false"></el-switch>
                        <!-- :disabled="
                            [5, 9, 11, 12].includes(scope.row.payModeId)
                          " -->
                      </template>
                    </el-table-column>
                    <el-table-column label="支付通道" align="center" prop="payChannelId" width="190">
                      <template slot-scope="scope">
                        <SelectRemote v-model="scope.row.payChannelId" style="width: 160px" :disabled="
                            [1, 2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 15,16].includes(
                              scope.row.payModeId
                            )
                          " :option="
                            $select({
                              key: 'listPayChannel',
                              option: {
                                clearable: true,
                                option: {
                                clearable: true,
                                  showItem: [
                                    {
                                      payChannelId: scope.row.payChannelId,
                                      payChannelName: scope.row.payChannelName,
                                    },
                                  ],
                                },
                              },
                            }).option
                          " />
                      </template>
                    </el-table-column>
                  </el-table>
                  <pagination class="pag" v-show="shopTotal > 0" :total="shopTotal" :page.sync="shopQueryParams.pageNum"
                    :limit.sync="shopQueryParams.pageSize" @pagination="getShopList" />
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </template>
    </cardTitleCom>
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import {
  getCellMouseEnter, //显示加减
  getCellMouseLeave, //隐藏加减
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import {
  listPaymode,
  delPaymode,
  batchPaymode,
  listCollectionPaymode,
  detailsCollectionPaymode,
  restoreShopPayMode,
} from "@/api/shop/base/paymode.js"; //付款方式
import { getDeptTree } from "@/api/shop/base/shopInfo"; //门店树
import { listShopInfo } from "@/api/shop/base/shopInfo"; //门店
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import Dialog2 from "@/components/Dialog"; //回收站
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { getHotKey, keys } from "@/components/Dialog/components/keycode.js";

export default {
  name: "ShopBaseCashierComponents",
  components: { cardTitleCom, Dialog2, SelectLocal, SelectRemote },
  data() {
    //自定义校验规则
    const setPayModeNo = (rule, value, callback) => {
      const val = rule.field.replace('"', "");
      const newVal = val.replace([], []);
      // if (!this.form[`${val}`]) {
      //   console.log(newVal)
      //   console.log(this.form[`${val}`])
      // } else {
      //   console.log('又进来了')
      // }
      if (value === undefined) {
        callback(new Error("请输入编号"));
        // this.$message.error("请输入编号");
      } else {
        callback();
      }
    };
    return {
      dialogOptions: {
        type: "ShopInfo",
        show: false,
        title: "回收站",
        width: "75vw",
        data: {
          getListApi: listPaymode,
          restoreListApi: restoreShopPayMode,
          id: "lineId",
          columns: [
            {
              prop: "payModeNo",
              label: "编号",
              minWidth: 180,
            },
            {
              prop: "payModeName",
              label: "名称",
              minWidth: 180,
            },
            {
              prop: "sortNo",
              label: "门店排序",
              minWidth: 100,
            },
            {
              prop: "hotKeyName",
              label: "快捷键",
              minWidth: 100,
            },
            {
              prop: "remark",
              label: "备注",
              minWidth: 120,
            },
            {
              prop: "updateBy",
              label: "修改人",
              minWidth: 120,
            },
            {
              prop: "updateTime",
              label: "修改时间",
              minWidth: 155,
            },
            {
              prop: "createBy",
              label: "创建人",
              minWidth: 120,
            },
            {
              prop: "createBy",
              label: "创建时间",
              minWidth: 155,
            },
          ],
        },
      },
      tableCellLabel: "序号",
      form: {},
      cashierForm: null,
      isOpenTree: true, //一键展开
      //tabs页控制的变量
      paymentType: 1,
      //标签页切换
      activeName: "universal",
      //遮罩层
      loading: false,
      //分页页码
      pageNum: 1,
      //分页页容量
      pageSize: 15,
      //列表数据总条数
      total: 0,
      //门店收款方式
      shopTotal: 0,
      //tree门店搜索名称
      shopName: undefined,
      //门店tree数据
      shopTreeOptions: [],
      //门店数据
      listShop: [],
      //门店付款方式列表
      shopCashierList: [],
      //tree默认展开/折叠
      isExpand: true,
      //表格分页码
      pageNum: 1,
      //表格分页页容量
      pageSize: 15,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        paymentType: 1,
      },
      // 门店收款方式查询参数
      shopQueryParams: {
        pageNum: 1,
        pageSize: 15,
        paymentType: 2,
      },
      //表单校验
      rules: {
        "cashierList.payModeNo": [
          {
            required: true,
            message: "编号不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[A-Za-z0-9]{1,20}$/,
            message: "请输入1 ~ 20个数值或英文的字符",
          },
        ],
        "cashierList.payModeName": [
          {
            required: true,
            message: "名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 50,
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 115;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    //门店列表
    const res = await listShopInfo({ pageNum: 1, pageSize: 10000000 });
    // getAllShopInfo
    this.listShop = res.rows;
    this.listShop.unshift({
      shopId: 0,
      shopNo: 0,
      shopName: "全部门店",
    });
    this.getList();
    this.getTreeselect();
  },
  watch: {
    // 根据名称筛选分类树
    shopName(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    //设置热键(快捷键)
    async setHotKey(e, index) {
      try {
        const { hotKeyName, hotKeyValue } = await getHotKey(e);
        this.$set(this.form.cashierList[index], "hotKeyName", hotKeyName);
        this.$set(this.form.cashierList[index], "hotkey", hotKeyValue);
      } catch (err) {
        this.$message.error(err);
      }
    },
    //门店
    selectShopChange(val, row) {
      if (val.some((item) => item == 0)) {
        this.$set(row, "shopIds", [0]);
      }
    },
    //还原刷新页面
    handleEvent() {
      this.loading = true;
      this.getList();
      this.loading = false;
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      this.form.cashierList = getCellMouseEnter(row, this.form.cashierList);
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      this.form.cashierList = getCellMouseLeave(this.form.cashierList);
      this.tableCellLabel = "序号";
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    //一键展开
    clickOpenTree() {
      this.isOpenTree = !this.isOpenTree;
      this.isExpand = !this.isExpand;
      this.buildData();
    },
    /** 删除按钮操作 */
    handleDelete(id) {
      this.$modal
        .confirm("确认删除此项数据项？")
        .then(() => {
          return delPaymode(id);
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
        .catch(() => { });
    },
    // 批量修改保存按钮方法
    async batchPaymode() {
      const valid = await this.$refs["form"].validate();
      console.log("valid", valid);
      // this.form.cashierList.paymentType = this.paymentType
      try {
        if (valid) {
          if (this.activeName == "universal") {
            await batchPaymode(this.form.cashierList);
            this.getList();
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } else {
            this.shopBatchPaymode();
          }
        }
      } catch (error) { }
    },
    // 门店批量修改保存按钮方法
    async shopBatchPaymode() {
      // this.form.cashierList.paymentType = this.paymentType
      await detailsCollectionPaymode(this.shopCashierList);
      await this.getShopList();
      this.$message({
        message: "保存成功",
        type: "success",
      });
    },

    // 通用刷新按钮
    refresh() {
      if (this.activeName == "universal") {
        this.queryParams = {
          pageNum: 1,
          pageSize: 15,
          paymentType: this.paymentType,
        };
        this.getList();
      } else {
        this.shopRefresh();
      }
    },
    // 门店刷新按钮
    async shopRefresh() {
      this.shopQueryParams = {
        pageNum: 1,
        pageSize: 15,
        paymentType: this.paymentType,
      };
      await this.getShopList();
      this.getTreeselect();
    },
    /** 查询机构下拉树结构 */
    getTreeselect() {
      getDeptTree().then((response) => {
        this.shopTreeOptions = response.data;
      });
    },
    /** 查询分类列表 */
    async getList() {
      this.loading = true;
      const response = await listPaymode(this.queryParams);
      this.form.cashierList = response.rows;
      this.form.cashierList.forEach((item) => {
        if (item.payModeId >= 1 && item.payModeId <= 16) {
          this.$set(item, "disable", true);
        } else {
          this.$set(item, "disable", false);
        }
        if (item.hotkey) {
          item.hotKeyName = item.hotkey
            ?.split(",")
            .map((x) => keys[x])
            .join("+");
        }
      });
      this.total = response.total;
      if (this.form.cashierList.length === 0) {
        this.form.cashierList = [
          {
            id: 1,
            payModeName: "",
            isUse: true,
            isRevenue: true,
            isDisplay: true,
            sortNo: 1,
          },
        ];
      }
      this.loading = false;
    },
    // 门店tree节点单击事件
    handleNodeClick(data) {
      this.shopQueryParams = {
        pageNum: 1,
        pageSize: 15,
        paymentShopId: data.id,
        paymentType: this.paymentType,
      };
      this.loading = true;
      listCollectionPaymode(this.shopQueryParams).then((response) => {
        this.shopCashierList = response.rows;
        this.shopTotal = response.total;
        this.loading = false;
      });
    },
    /** 查询门店列表 */
    async getShopList() {
      this.loading = true;
      const res = await listCollectionPaymode(this.shopQueryParams);
      this.shopCashierList = res.rows;
      this.shopTotal = res.total;
      this.loading = false;
    },
    //点击一键展开事件
    getExpand() {
      this.isExpand = !this.isExpand;
      this.buildData();
    },
    //遍历树的所有子节点，展开的时候给子节点展开状态赋值true，折叠时候赋值false
    buildData() {
      for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = this.isExpand;
      }
    },
    //tab栏切换事件
    async handleClick(tab) {
      this.paymentType = tab.label == "门店收款设置" ? 2 : 1;
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        paymentType: this.paymentType,
      };

      if (this.paymentType == 1) {
        //通用收款设置
        await this.getList();
      } else {
        //门店收款设置
        await this.getShopList();
      }
    },
    // 门店tree筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        // this.form.cashierList.splice(index + 1, 0, {
        //   isUse: true,
        //   isRevenue: true,
        //   isDisplay: true,
        //   sortNo: 1,
        //   hoverRow: false
        // })
        this.form.cashierList.unshift({
          isUse: true,
          isRevenue: true,
          isDisplay: true,
          sortNo: 1,
          hoverRow: false,
        });
        if (this.$refs.multipleTable.bodyWrapper.scrollTop != 0) {
          this.$nextTick(() => {
            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
          });
        }
      } else {
        if (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,16].includes(
            this.form.cashierList[index].payModeId
          )
        ) {
          return;
        }
        if (this.form.cashierList[index].lineId) {
          const id = this.form.cashierList[index].lineId;
          this.handleDelete([id]);
        } else {
          this.form.cashierList.splice(index, 1);
        }
        if (this.form.cashierList.length == 0) {
          this.form.cashierList = [
            {
              isUse: true,
              isRevenue: true,
              isDisplay: true,
              sortNo: 1,
              hoverRow: false,
            },
          ];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 通用收款设置区域
  .universal-cashier {
    //源单信息表格
    .tableContent {
      margin: 0 10px 10px 10px;
      .left-main {
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
        ::v-deep .el-form-item__error {
          position: static;
        }
        ::v-deep .el-form-item {
          margin-bottom: 0;
        }
        // 按钮
        .btn {
          text-align: right;
          margin-bottom: 10px;
        }
      }
    }
  }

  //门店收款设置区域
  .shop-cashier {
    .left-main {
      display: flex;
      margin: 0 10px 10px 10px;
      .treeBox {
        width: 260px;
        .title {
          margin-bottom: 15px;
        }
      }

      .right-main {
        width: calc(100% - 250px);
        margin-left: 15px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.btnBox {
  text-align: right;
  margin-bottom: 10px;
}
.pag {
  margin: 10px 0 !important;
  height: 30px;
}
::v-deep .el-pagination {
  margin: 0 !important;
}
//分页
//搜索tree
.searchTree {
  width: 230px;
}

//一键展开
.iconDown {
  width: 20px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 13px;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2),
.el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2) {
  padding-left: 8px;
}
/* element滚动条组件 隐藏水平滚动条 */
::v-deep .sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .is-horizontal {
  display: none;
}
::v-deep ::-webkit-scrollbar-track {
  background: transparent;
}
</style>
